<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <v-btn text color="grey" class="rounded-lg" @click="$router.go(-1)">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
            <v-spacer />
            <p class="pink--text font-weight-bold mr-5">
              {{ $route.params.name }}
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="8">
          <v-card flat rounded="lg">
            <v-card-text>
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div class="d-flex justify-space-between mb-3">
                  <div>
                    <p class="pink--text font-weight-bold ">
              {{ $route.params.name }}
            </p>
                  </div>
                  <div>
                    <v-btn
                      class="primary"
                      rounded
                      elevation="0"
                      @click="addCommission"
                    >
                      Nouvelle commission
                    </v-btn>
                  </div>
                </div>
                <v-divider class="mb-3"></v-divider>
                <div v-if="commissions.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          class="bg-snipper"
                          v-for="item in commissions.data"
                          :key="item.id"
                        >
                          <td class="font-weight-bold text-center">
                            {{ item.service.name }}
                          </td>
                          <td class="font-weight-bold text-center">
                            {{ item.commission }} %
                          </td>
                          <td>
                            <v-btn
                              class="red--text"
                              icon
                              @click="deleteCommission(item.id)"
                            >
                              <v-icon>mdi mdi-delete</v-icon>
                            </v-btn>

                            <v-btn
                              class="blue--text"
                              icon
                              @click="updateCommission(item)"
                            >
                              <v-icon>mdi mdi-pen</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                      filled
                      v-model="filter.size"
                      style="width: 20px"
                      dense
                      :items="[10, 20, 30, 40, 50]"
                      @change="onPageChange"
                    ></v-select>
                    <v-spacer />
                    <v-pagination
                      total-visible="6"
                      v-if="commissions.total > 15"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(commissions.total / filter.size)"
                      @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <AddCommisionDialog ref="addCommission" @refresh="fetchData" />
      <UpdateCommissionDialog ref="updateCommission" @refresh="fetchData" />
    </div>
  </v-container>
</template>

<script>
import AddCommisionDialog from "./AddCommisionDialog.vue";
import UpdateCommissionDialog from "./UpdateCommissionDialog.vue";
import { HTTP } from "@/http-common";

export default {
  components: { AddCommisionDialog, UpdateCommissionDialog },
  data() {
    return {
      filter: {
        user_id: this.$route.params.id,
        size: 10,
      },
      delete_id: null,
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchCommissions", {
        filter: this.filter,
      });
    },
    onPageChange() {
      this.fetchData();
    },
    addCommission() {
      this.$refs.addCommission.open(this.$route.params.id);
    },
    deleteCommission(id) {
      this.delete_id = id;
      this.$confirm_dialog = true;
    },
    removeCommission() {
      HTTP.delete("/v1/commissions/" + this.delete_id, { params: {} })
        .then(() => {
          this.delete_id = null;
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateCommission(item) {
      this.$refs.updateCommission.open(item);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.commissionsLoading;
    },
    commissions() {
      return this.$store.getters.getCommissions;
    },
  },
  watch: {
    $is_confirm: function (el) {
      console.log(el);
      if (el) {
        this.removeCommission();

        this.action = null;
        this.$is_confirm = false;
      }
    },
  },
  mounted() {
    this.fetchData();

    this.$store.dispatch("fetchServices", {
      filter: {
        size: 1000, // no pagination
        for_form: true
      },
    });
  },
};
</script>